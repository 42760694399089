import React from 'react'

import './Home.css'
import Footer from "../components/Footer";

function Home () {
    return (
      <>
        <div className='hero-container'>
          <h1>Foster Consulting</h1>
          <p>Your trusted experts in Cloud Technologies.</p>
        </div>
      </>
    );
}

export default Home;
