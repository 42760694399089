import React from 'react'

import './Contact.css'
import Footer from "../components/Footer";

function Contact () {
    return (
      <>
        <div className='contact-container'>
          <h1>Transform today!</h1>
          <p>michael.foster@misolutions.dev</p>
        </div>
      </>
    );
}

export default Contact;
