import React from 'react'

import './Services.css'
import Footer from "../components/Footer";

function Services () {
    return (
      <>
        <div className='services-container'>
          <h1>Our Cloud Specializations: </h1>
          <p>
            <ul>
              <li>Kubernetes</li>
              <li>Google Cloud Platform</li>
              <li>Terraform</li>
              <li>ArgoCD</li>
            </ul>
          </p>
        </div>
      </>
    );
}

export default Services;
