import React from 'react'
import {Button} from "./Button";
import './Footer.css'
import {Link} from "react-router-dom";

function Footer() {
    return (
        <div className='footer-container'>
            <section className="footer-subscription">
                <p className="footer-subscription-heading">
                    Join the Iron Sauce newsletter for motivation and training plans
                </p>
                <p className="footer-subscription-text">
                    You can unsubscribe at any time.
                </p>
                <div className="input-areas">
                    <form>
                        <input type="email" name="email" placeholder="Your Email"
                        className="footer-input"/>
                        <Button buttonStyle='btn--outline'>Subscribe</Button>
                    </form>
                </div>
            </section>
            <div className="footer-links">
                <div className="footer-link-wrapper">
                    <div className="footer-link-items">
                        <h2>About Us</h2>
                        <Link to='/sign-up'>Sign Up</Link>
                        <Link to='/'>Hold This</Link>
                        <Link to='/'>Hold This Too</Link>
                        <Link to='/'>Hold This Finally</Link>
                    </div>
                </div>
            </div>
            <section className="social-media">
                <div className="social-media-wrap">
                    <div className="footer-logo">
                        <Link to='/' className="social-logo">
                            IRON SAUCE <i className="fab-typo3"></i>
                        </Link>
                    </div>
                    <small className="website-rights">IRON SAUCE 2023</small>
                    <div className="social-icons">
                        <Link
                            className="Link social-icon-link instagram"
                            to='/'
                            target='_blank'
                            aria-label='Instagram'
                        >
                            <i className="fab fa-instagram"></i>
                        </Link>
                        <Link
                            className="Link social-icon-link youtube"
                            to='/'
                            target='_blank'
                            aria-label='Youtube'
                        >
                            <i className="fab fa-youtube"></i>
                        </Link>
                        <Link
                            className="Link social-icon-link tiktok"
                            to='/'
                            target='_blank'
                            aria-label='Tiktok'
                        >
                            <i className="fab fa-tiktok"></i>
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer
