// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/background-image3.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-container {
    /* background: url('../../public/images/mountain1.jpg') center center/cover no-repeat; */
    /* background: url("../../public/background-image.png") repeat scroll 0 0 #D1D1D1; */
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) repeat scroll 0 0 #D1D1D1;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: fill;
}

.hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
}

.hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS';
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home.css"],"names":[],"mappings":"AAAA;IACI,wFAAwF;IACxF,oFAAoF;IACpF,6EAA+E;IAC/E,aAAa;IACb,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,8CAA8C;IAC9C,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,eAAe;IACf,2BAA2B;AAC/B","sourcesContent":[".hero-container {\n    /* background: url('../../public/images/mountain1.jpg') center center/cover no-repeat; */\n    /* background: url(\"../../public/background-image.png\") repeat scroll 0 0 #D1D1D1; */\n    background: url(\"../../public/background-image3.png\") repeat scroll 0 0 #D1D1D1;\n    height: 100vh;\n    min-height: 100vh;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);\n    object-fit: fill;\n}\n\n.hero-container > h1 {\n    color: #fff;\n    font-size: 100px;\n    margin-top: -100px;\n}\n\n.hero-container > p {\n    margin-top: 8px;\n    color: #fff;\n    font-size: 32px;\n    font-family: 'Trebuchet MS';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
