// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services-container {
    /* background: url('../../public/images/mountain1.jpg') center center/cover no-repeat; */
    /* background: url("../../public/background-image.png") repeat scroll 0 0 #D1D1D1; */
    /* background: url("../../public/background-image3.png") repeat scroll 0 0 #D1D1D1; */
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: fill;
}

.services-container > h1 {
    color: #555;
    align-items: center;
    font-size: 80px;
    margin-top: -250px;
}

.services-container > p {
    margin-top: 64px;
    align-items: center;
    color: #05f;
    font-size: 32px;
    font-family: 'Trebuchet MS';
}
`, "",{"version":3,"sources":["webpack://./src/pages/Services.css"],"names":[],"mappings":"AAAA;IACI,wFAAwF;IACxF,oFAAoF;IACpF,qFAAqF;IACrF,aAAa;IACb,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,8CAA8C;IAC9C,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,2BAA2B;AAC/B","sourcesContent":[".services-container {\n    /* background: url('../../public/images/mountain1.jpg') center center/cover no-repeat; */\n    /* background: url(\"../../public/background-image.png\") repeat scroll 0 0 #D1D1D1; */\n    /* background: url(\"../../public/background-image3.png\") repeat scroll 0 0 #D1D1D1; */\n    height: 100vh;\n    min-height: 100vh;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);\n    object-fit: fill;\n}\n\n.services-container > h1 {\n    color: #555;\n    align-items: center;\n    font-size: 80px;\n    margin-top: -250px;\n}\n\n.services-container > p {\n    margin-top: 64px;\n    align-items: center;\n    color: #05f;\n    font-size: 32px;\n    font-family: 'Trebuchet MS';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
